import React, { Component } from 'react';
import SwaggerUI from "swagger-ui";
import { Storage } from 'aws-amplify';
import { ProgressBar } from 'react-materialize'
import 'swagger-ui/dist/swagger-ui.css';

class Swagger extends Component {
    constructor() {
        super();
        this.state = {
            loaded: false
        };
    }

  componentDidMount() {

    Storage.get(`clients/victory_solutions/api/swagger.yaml`)
        .then (url => {
            SwaggerUI({dom_id: "#swaggerui", url})
            this.setState({loaded: true})
        })
  }

  render() {
    const { loading } = this.state
    if(loading){
        return <ProgressBar />
    }else{
       return <div id="swaggerui" /> 
    }

  }
  
}


export default Swagger;