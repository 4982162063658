import React, { Component } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import Swagger from './Swagger'
import awsmobile from './aws-exports';



//Amplify.configure(awsmobile);

Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: awsmobile.aws_cognito_identity_pool_id, 
      // REQUIRED - Amazon Cognito Region
      region: awsmobile.aws_cognito_region, 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: awsmobile.aws_user_pools_id,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: awsmobile.aws_user_pools_web_client_id, 
  },
  "aws_appsync_graphqlEndpoint": awsmobile.aws_appsync_graphqlEndpoint,
  "aws_appsync_region": awsmobile.aws_appsync_region,
  "aws_appsync_authenticationType": awsmobile.aws_appsync_authenticationType,
  "aws_project_region":awsmobile.aws_project_region,
  "aws_content_delivery_bucket": awsmobile.aws_content_delivery_bucket,
  "aws_content_delivery_bucket_region": awsmobile.aws_content_delivery_bucket_region,
  "aws_content_delivery_url": awsmobile.aws_content_delivery_url,
  "aws_user_files_s3_bucket": awsmobile.aws_user_files_s3_bucket,
  "aws_user_files_s3_bucket_region": awsmobile.aws_user_files_s3_bucket_region
});

class App extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  render() {
   
    return (
      <div className="App">
        <Swagger />
      </div>
    );
  }
}

export default withAuthenticator(App, true);
